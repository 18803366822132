<template>
  <v-app style="background-color: #F0F0F0;">
    <v-main>
      <v-container fluid>
        <v-row class="ml-11 mt-0">
          <v-col>
            <v-card min-height="300">
              <v-card-title>
                Knowledge extract
              </v-card-title>
              <v-card-text>
                <v-textarea
                label="Input text"
                v-model="inputsentence"
                :value="inputsentence"
                rows="6">
                </v-textarea>
              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn
                depressed
                color="primary"
                @click="extractSPO"
                
              >
                Extract
              </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card min-height="300">
              <v-card-title>
                Extracted result
              </v-card-title>
              <v-card-text >
                <div ref="networkContainer" class="network-container" style="height: 255px;"></div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ml-11 mt-0">
          <v-col>
            <v-card>
              <v-card-title>Knowledge Graph Explore</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="11">
                    <v-textarea
                      label="Ask me some question"
                      v-model="searchingquery"
                      :value="searchingquery"
                      hint="Enter your question"
                      rows="1">
                    </v-textarea>
                    </v-col>
                    <v-col >
                      <div class="d-flex align-end">
                        <v-btn
                          depressed
                          color="primary"
                          @click="askquestion"
                          large
                        >
                          SEND
                        </v-btn>  
                      </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card flat min-height="500">
                      <v-card-text>
                        <div ref="questionresult" class="network-container" style="height: 900px;"></div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
    
</template>

<script>
import {DataSet} from 'vis-data/peer';
import {Network} from 'vis-network/peer';
import axios from 'axios';
export default {
  name: 'DashboardMain',
  components: {},
  data(){
    return{
      inputsentence:"",
      extractresult:"",
      searchingquery:"",

    }
  },
  methods:{
    extractSPO(){
      try{
        axios.post('/api/TripleExtract',{sentence:this.inputsentence,save:'No'})
        .then((response) =>{
          if (response.data.status=='error'){
          console.log("extract error");
        }
          else{
            this.extractresult=response.data.data
            console.log(this.extractresult)
            // this.inputsentence=""
            this.renderGraph(response.data.data)

          }
        }).catch( (error) => console.log(error))
      }
      catch(e){
        console.log("Setting failed")
      }
    },
    askquestion(){
      try{
        axios.post('/api/askquestion',{query:this.searchingquery})
        .then((response) =>{
          if (response.data.status=='error'){
          console.log("extract error");
        }
          else{
            var answer=response.data.data[0]
            console.log(answer)
            var nodedata=response.data.data[0].nodedata
            var edgedata=response.data.data[0].edgedata
            var tagettype=response.data.data[0].predicate
            var start=response.data.data[0].simnode
            this.displayanswer(nodedata,edgedata,tagettype,start)

          }
        }).catch( (error) => console.log(error))
      }
      catch(e){
        console.log("Setting failed")
      }
    },
    createnodeipmapping(nodedata,nodename,idmappingtable){
      if(! (nodename in idmappingtable)){
        var item={ id: nodedata.length+1, label: nodename, color: '#99BBFF'}
        idmappingtable[nodename]=nodedata.length+1
        nodedata.push(item)
      }
      return nodedata,idmappingtable
    },
    renderGraph(graph) {
      var node =[]
      var edge=[]
      var idmappingtable={}
      // 提供的节点和边数据
      for(var i =0;i<graph.length;i++){
        console.log(graph[i])
        // node item :{ id: 1, label: 'Node 1' }
        // edge item :{ from: 1, to: '2' }
        var subject=graph[i].subject
        var object=graph[i].object
        var edgename=graph[i].edge
        node,idmappingtable=this.createnodeipmapping(node,subject,idmappingtable)
        node,idmappingtable=this.createnodeipmapping(node,object,idmappingtable)
        var edgeitem={from : idmappingtable[subject], to : idmappingtable[object], arrows: 'to', label: edgename}
        edge.push(edgeitem)
      }
      const nodes = new DataSet(node)
      const edges = new DataSet(edge)
      // 创建配置选项
      const options = {
        nodes: {
          shape: 'dot',
          size: 50
        },
        edges: {
          width: 2
        },
        interaction: {
          dragNodes: true, // 允許拖曳節點
      },
        physics: false // 禁用物理模擬
      };

      // 创建网络对象并绘制图形
      const container = this.$refs.networkContainer;
      const data = { nodes: nodes, edges: edges };
      this.network = new Network(container, data, options);
    },
    displayanswer(node,edge,target,start){
      console.log(node)
      console.log(edge)
      console.log(start)
      var nodedata=[]
      var edgedata=[]
      for (var i=0;i<node.length;i++){
        var item={id: node[i].nodeid, label: node[i].title,  color: '#DDDDDD'}
        if(node[i].label == target){
          item.color="#99FF99"
        }
        if(node[i].title == start){
          console.log("Find start point")
          item.color="#FFFF77"
        }
        nodedata.push(item)
      }
      for (var a=0; a < edge.length;a++){
        var edgeitem={from: edge[a].form , to: edge[a].to, arrows: 'to', label: edge[a].type}
        edgedata.push(edgeitem)
      }
      const nodes = new DataSet(nodedata)
      const edges = new DataSet(edgedata)
      // 创建配置选项
      const options = {
        nodes: {
          shape: 'dot',
          size: 50,
          font: {
            size: 20 
          }
        },
        edges: {
          width: 2
        },
        interaction: {
          dragNodes: true, // 允許拖曳節點
      },
      physics: {
      enabled: true,
      barnesHut: {
          gravitationalConstant: -2000,
          centralGravity: 0.3,
          springLength: 200,
          springConstant: 0.004,
          damping: 0.09,
          avoidOverlap: 0.8 // 调整此参数以避免节点重叠
        }
      }
      };

      // 创建网络对象并绘制图形
      const container = this.$refs.questionresult;
      const data = { nodes: nodes, edges: edges };
      this.network = new Network(container, data, options);
    }

  }
}
</script>


