import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardMain from "@/components/DashboardMain.vue"
Vue.use(VueRouter)

const routes=[
    {
        name:"home",
        path:"/",
        component:DashboardMain
    }
]
const router=new VueRouter({
    routes,
    mode:'history'
})
export default router