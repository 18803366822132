import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Highcharts from "highcharts"
import highchartsNetworkgraph from 'highcharts/modules/networkgraph';
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsVue from 'highcharts-vue'
import router from './router'
import axios from 'axios'
Vue.config.productionTip = false
HighchartsMore(Highcharts)
highchartsNetworkgraph(Highcharts);
Vue.use(HighchartsVue, {highcharts: Highcharts})
Vue.use(vuetify,{iconfont:'mdi'})
Vue.prototype.$axios = axios;
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
